<template>
  <div class="tab-content col-lg-10 col-md-10 ">
    <div class="iq-card-body p-0">
      <div class="row">
        <LeftMenu :menuId="5"></LeftMenu>
        <div class="col-lg-9">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('label.recommendedUsers')}}</h4>
            </template>
            <template v-slot:body>
              <button type="button" @click="toggleActive(0)" :class="
                    selecded === 0 ? 'btn-primary' : 'btn-outline-primary'
                  " class="btn rounded-pill mb-3 me-1">
                {{$t('label.creator')}} {{total_creator}}
              </button>
              <button type="button" @click="toggleActive(1)" :class="
                    selecded === 1 ? 'btn-primary' : 'btn-outline-primary'
                  " class="btn rounded-pill mb-3 me-1">
                {{$t('label.signupUser')}} {{total_signup}}
              </button>

              <div class="allow-scroall" id="scrollbar-style-1">
                <table class="b-table m-responsive m-earnings">
                  <thead>
                    <tr>
                      <th>{{$t('table.dateTime')}}</th>
                      <th>{{$t('table.account')}}</th>
                    </tr>
                  </thead>
                  <tbody class="table-c">
                    <tr v-for="(item, index) in list" :key="index">
                      <td :data-title="$t('table.dateTime')" class="b-table__date">
                        <span class="b-table__date__date">
                          <span title=""> {{item.created_date}}</span>
                        </span>
                        <span class="b-table__date__time">
                          <h6 title="">{{item.created_time}}</h6>
                        </span>
                      </td>
                      <td :data-title="$t('table.account')" class="b-table__net">
                        <strong>
                          <span>{{item.user_account}} （{{item.user_name}}）</span>
                        </strong>
                      </td>
                    </tr>
                    <tr v-if="!list.length" class="table-emtpy-text">
                      <td colspan="2" class="text-center">{{ $t('此列表為空') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftMenu from '../User/Components/LeftMenu'
import { getRferrals } from '@/api/user'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
export default {
  name: 'ReferralsUserList',
  components: {
    LeftMenu
  },
  data() {
    return {
      selecded: 0,
      primaryKey: 'created_at',
      commomData: commomData(),
      list: [],
      total_creator: 0,
      total_signup: 0
    }
  },
  methods: {
    toggleActive(v) {
      this.selecded = v
      this.commomData.noResult = false
      this.commomData.condition.page = 1
      this.getRows()
    },
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.type = this.selecded ? 'signUp' : 'creator'
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.page_size = 100
      await getRferrals(this.commomData.condition)
        .then(res => {
          this.list = res.data
          this.total_creator = res.total_creator
          this.total_signup = res.total_signup
        })
        .catch(err => {
          console.log(err)
          this.commomData.btnLoader = false
          this.$swal.mixin().fire({
            icon: 'error',
            title: 'err.response.data.message'
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  mounted() {
    this.getRows()
  }
}
</script>
